import { css } from '@emotion/core'
import React from 'react'
import { MyPostingsMenuLink } from '~/components/app/Header/MyPostingsMenuLink'
import Container from '~/components/common/Container'
import { useFlags } from '~/context/FlagsContext'
import { LogoLink } from './LogoLink'
import { PostJobMenuLink } from './PostJobMenuLink'
import { ProfileMenuLink } from './ProfileMenuLink'
import { SignupMenuLink } from './SignupMenuLink'
import { SavedJobsMenuLink } from '~/components/app/Header/SavedJobsMenuLink'
import { FindJobsMenuLink } from '~/components/app/Header/FindJobsMenuLink'
import { useMeQuery } from '~/generated/graphql'

const navStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
`
const navContainerStyles = css`
  display: flex;
  align-items: center;
  > * {
    margin-left: 15px;
  }
`
const logoStyles = css`
  width: 150px;
`

interface Props {
  isReducedNav?: boolean
  hasSuperPostFeature?: boolean
}

export const DesktopHeader = ({ isReducedNav, hasSuperPostFeature, ...rest }: Props) => {
  const { data: meData } = useMeQuery({ fetchPolicy: 'cache-only' })
  const currentUser = meData?.me
  const flags = useFlags()

  const showFindJobsLink = !isReducedNav
  const showMyPostingsLink = !isReducedNav && currentUser?.loggedInRealm === 'employer'
  const showSavedJobsLink = !isReducedNav && currentUser?.loggedInRealm === 'jobseeker'
  const showSignupLink = !currentUser
  const showPostJobLink = !isReducedNav && flags.showPostJobLink

  return (
    <Container {...rest}>
      <div css={navStyles}>
        <LogoLink imageCss={logoStyles} />
        <div css={navContainerStyles}>
          {showFindJobsLink ? <FindJobsMenuLink currentUser={currentUser} /> : null}
          {showMyPostingsLink ? <MyPostingsMenuLink currentUser={currentUser} /> : null}
          {showSavedJobsLink ? <SavedJobsMenuLink currentUser={currentUser} /> : null}
          <ProfileMenuLink />
          {showSignupLink ? <SignupMenuLink /> : null}
          {showPostJobLink ? <PostJobMenuLink hasSuperPostFeature={hasSuperPostFeature} /> : null}
        </div>
      </div>
    </Container>
  )
}
