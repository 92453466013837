import React from 'react'
import { MobileHeader } from './MobileHeader'
import { DesktopHeader } from './DesktopHeader'
import { css } from '@emotion/core'
import { DESKTOP_OR_MORE } from '~/constants/theme.styles'

type Props = {
  raised?: boolean
  isReducedNav?: boolean
  hasSuperPostFeature?: boolean
}

export const SiteNav: React.FC<Props> = ({ raised, isReducedNav, hasSuperPostFeature }) => {
  return (
    <div
      data-cy="header"
      css={[
        css`
          width: 100%;
          background: #fff;
        `,
        raised
          ? css`
              box-shadow: 0 0px 3px 2px rgb(113, 113, 113, 14%);
            `
          : null
      ]}
    >
      <MobileHeader
        hasSuperPostFeature={hasSuperPostFeature}
        isReducedNav={isReducedNav}
        css={css`
          @media ${DESKTOP_OR_MORE} {
            display: none;
          }
        `}
      />
      <DesktopHeader
        hasSuperPostFeature={hasSuperPostFeature}
        isReducedNav={isReducedNav}
        css={css`
          display: none;
          @media ${DESKTOP_OR_MORE} {
            display: block;
          }
        `}
      />
    </div>
  )
}
