import React from 'react'
import Link from 'next/link'
import { AnchorButton } from '~/components/common/Button/Button'
import { css } from '@emotion/core'
import { signupUrl } from '~/lib/links'

export const SignupMenuLink = () => {
  return (
    <Link href={signupUrl} passHref>
      <AnchorButton
        uppercase
        extraCss={css`
          padding-top: 9px;
          padding-bottom: 9px;
          font-size: 12px;
          margin-left: 25px;
          margin-right: 10px;
        `}
      >
        Sign Up
      </AnchorButton>
    </Link>
  )
}
